/* Add this CSS to your project to style the home page */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* height: 100vh; */
  /* background:linear-gradient(rgb(19, 74, 255), rgb(175, 200, 224)); */
}
.description h1 {
  color: black;
}

/* //////////////////////////////// */
header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  width: 80%;
  /* background-color: #0056b3; */
}

.dashboard-logo {
  max-width: 200px;
  height: auto;
  padding: 10px;
}

.Empl0yee-data {
  flex: 1;
  padding-top: 20px;
  color: black;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  /* background-color: #007bff; */
  /* display: flex; */
  /* text-align: end; */
  text-align: end;
  /* align-items: center; */
}

.useremail {
  font-weight: 300;
  font-size: 15px;
  font-family: "Times New Roman", Times, serif;
}

@media screen and (max-width: 768px) {
  .dashboard-logo {
    max-width: 150px;
  }
  .Empl0yee-data {
    flex-basis: 100%;
    text-align: center;
    padding: 0;
  }
  .useremail {
    font-size: 14px;
  }
  table {
    width: 70%;
    border-collapse: collapse;
    margin-top: 20px;
  }
}
/* ////////////////////////////// */

/* .user {
    font-weight: bold;
    font-size: 24px;
    color: black;
    padding-top: 50px;
    padding-right: 90px;
  }
  .useremail{
    color: black;
    font-size: 20px;
    padding-right: 90px;
  } */
main {
  /* text-align: center;
    background-color: #a6b9cc;
 width: 80%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  /* background-color: #007bff; */
}
.buttons {
 text-align: center;
  /* background-color: #4caf50; */
}
button{
  border-radius: 10px;
}
.Project-detials {
  float: left;
  /* background-color: #333; */
}
.description {
  margin-bottom: 20px;
  color: black;
  text-align: center;
}

button {
  margin: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}
/* //////////////////// Login Css */
.App {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 20px 0px 40px;
  background: linear-gradient(rgb(44, 26, 122), rgb(130, 118, 238));
}

.logo {
  color: #fff;
  height: 90px;
  padding-left: 10px;
}
.loginContainer {
  /* display: flex;
    flex-direction: column;
    align-items: center; */
  width: 90%;
  max-width: 350px;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 25px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.input-container input {
  border-radius: 10px;
  border: 2px solid rgb(166, 159, 255);
  outline: none;
  padding: 10px;
  font-size: 16px;
}

.loginBut {
  height: 3em;
  width: 90%;
  max-width: 250px;
  margin: 10px;
  border-radius: 10px;
  background: linear-gradient(rgb(44, 26, 122), rgb(130, 118, 238));
  border: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.loginBut:hover {
  transform: scale(1.1);
  box-shadow: 0 0 12px rgb(199, 199, 199);
}

.loginBut p {
  color: white;
  font-size: 20px;
  margin: 0;
}

.siG {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  height: 3em;
  width: 100%;
  max-width: 250px;
  margin: 10px;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  border: 3px dashed rgb(152, 145, 255);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.siG:hover {
  transform: scale(1.1);
  box-shadow: 0 0 12px rgb(199, 199, 199);
}

.siG p {
  font-size: 18px;
  margin: 0;
}
a {
  color: rgb(0, 68, 255);
  font-weight: 500;
  cursor: pointer;
  align-self: baseline;
  /* margin-left: 40px; */
}
@media (max-width: 768px) {
  .loginContainer {
    width: 90%;
    max-width: 300px;
  }

  .loginBut {
    max-width: 200px;
  }

  .siG {
    max-width: 200px;
  }

  .logo {
    color: #fff;
    height: 50px;
    padding-left: 20px;
  }
}
/* ///////////////////Login Css */

.Sign-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

/* /////////////////////////////////////////////////end sign up css */

/* ///////////////////////////////////////////sale form style start */
/* Add this CSS in a separate .css or .scss file and import it into your component */

/* Add this CSS in a separate .css or .scss file and import it into your component */
.datetime {
  /* background-color: #007bff; */
  display: flex;
  justify-content: end;
}
.datetime input {
  background-color: #f5f5f5;
  border: none;
}
.your-form-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f8f8;
  margin-bottom: 40px;
}
.your-form-container h2 {
  text-align: center;
  margin-bottom: 50px;
  font-family: "Times New Roman", Times, serif;
}
.your-form-container label {
  font-weight: 500;
  font-family: "Times New Roman", Times, serif;
}
.your-form-container form .form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}
/* .your-form-container form .form-row input {
  width:90%;
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
 
} */

.your-form-container form .form-row > div,
.your-form-container form .form-row > label {
  flex: 1;
  padding: 5px;
}

.form-row label {
  /* text-align: right; */
}

.your-form-container form .form-row input {
  width: 80%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

select {
  width: 40%;
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

.form-buttons button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-buttons button[type="submit"] {
  background-color: #4caf50;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .form-row > div,
  .form-row label {
    flex: 1;
  }
}

/* /////////////////////////////////////////// sales form end */

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table tbody tr select {
  width: 80%;
  padding: 5px;
  /* margin: 5px 0; */
  border: 1px solid #ccc;
  border-radius: 4px;
}
/* Style table headers */
th {
  background-color: #333;
  color: white;
  text-align: left;
  padding: 10px;
}

/* Style table rows */
tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Style table data cells */
td {
  padding: 10px;
  border: 1px solid #ddd;
}

/* Responsive CSS */
@media screen and (max-width: 767px) {
  table th,
  table td {
    display: block;
    width: 95%;
  }

  table thead {
    display: none;
  }

  table tbody tr {
    margin-bottom: 10px;
    display: block;
  }
  select {
    width: 70%;
    padding: 5px;
    /* margin: 5px 0; */
    border: 1px solid #ccc;
    border-radius: 4px;
    display: block;
  }
  table tbody tr select {
    width: 100%;
    margin: 5px 0;
  }
}
